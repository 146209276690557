// Include the following lines to define the gtag() function when
// calling this code prior to your gtag.js or Tag Manager snippet
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}

// Call the default command before gtag.js or Tag Manager runs to
// adjust how the tags operate when they run. Modify the defaults
// per your business requirements and prior consent granted/denied, e.g.:
gtag('consent', 'default', {
  'ad_storage': 'denied',
  'analytics_storage': 'denied',
  'ad_user_data': 'denied',
  'ad_personalization': 'denied'
});